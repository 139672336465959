import React, { useContext, useState } from 'react';
import { Helmet } from 'react-helmet';

import TrackableCTA from 'components/utils/TrackableCTA';
import AccountLink from 'components/header/AccountLink';
import CustomLink from 'components/utils/CustomLink';
import { LangContext } from 'context/LangContext';
import FormattedMessage, { getFormattedMessage } from 'components/utils/FormattedMessage';
import * as style from 'styles/components/header/menu.module.scss';

const Menu = ({ data }) => {
    const { lang } = useContext(LangContext);

    const currentLangMenu = data.filter((menu) => menu.language.hreflang === lang.hreflang)[0];
    let currentPageSlug = null;
    if (typeof window !== 'undefined') {
        currentPageSlug = window.location.pathname;
    }

    const [isMenuMobileOpen, toggleMenuMobile] = useState(false);
    const isMenuMobileOpenClass = isMenuMobileOpen ? 'open' : 'close';

    const getLinkClass = (pageSlug) => {
        if (currentPageSlug?.slice(1) === pageSlug) return 'text-nav active';
        return 'text-nav';
    };

    return (
        <>
            <button
                className={`btn-reset ${style.menu__toggle} ${isMenuMobileOpenClass}`}
                onClick={() => toggleMenuMobile(!isMenuMobileOpen)}
                onKeyDown={() => {}}
                type="button"
                aria-label={getFormattedMessage('header.menu', lang)}
            >
                <i className="ico-menu" aria-hidden="true" />
                <i className="ico-cross" aria-hidden="true" />
                <span className="visually-hidden">
                    <FormattedMessage id="header.menu" />
                </span>
            </button>
            {isMenuMobileOpen &&
                <>
                    <Helmet>
                        <body data-modal-open="true" />
                    </Helmet>
                    <div aria-hidden className={style.menu__backdrop} onClick={() => toggleMenuMobile(false)} />
                </>
            }
            <div className={`${style.menu__wrapper} ${isMenuMobileOpenClass}`}>
                <nav>
                    <ul className={style.menu__nav}>
                        {isMenuMobileOpen &&
                        <li
                            className={style.menu__item}
                            role="presentation"
                        >
                            <a
                                className="text-nav"
                                href="/sok"
                            >
                                <i className="ico-search" aria-hidden="true" /> <FormattedMessage id="search.search" />
                            </a>
                        </li>
                        }
                        {currentLangMenu.items
                            .filter((item) => item.title && (item.page || item.externalUrl))
                            .map((item) => (
                                <li
                                    key={item.id}
                                    className={style.menu__item}
                                    role="presentation"
                                    onClick={() => toggleMenuMobile(false)}
                                >
                                    {item.page && (
                                        <CustomLink
                                            className={getLinkClass(item.page.slug.current)}
                                            to={`/${item.page.slug.current}`}
                                            target={item.external ? '_blank' : '_self'}
                                            isTranslated={false}
                                        >
                                            {item.title}
                                        </CustomLink>
                                    )}

                                    {!item.page && item.externalUrl && (
                                        <TrackableCTA
                                            ctaText={item.title}
                                            location="Main menu external"
                                            destination={item.externalUrl}
                                        >
                                            <a
                                                className="text-nav"
                                                href={item.externalUrl}
                                                rel="noreferrer"
                                                target={item.external ? '_blank' : '_self'}
                                            >
                                                {item.title}
                                            </a>
                                        </TrackableCTA>
                                    )}
                                </li>
                            ))}
                        {!isMenuMobileOpen &&
                            <li>
                                <a
                                    className={`text-nav ${style.menu__item__search}`}
                                    href="/sok"
                                    aria-label="Search"
                                >
                                    <i className="ico-search" aria-hidden="true" />
                                </a>
                            </li>
                        }
                        {isMenuMobileOpen &&
                            <>
                                <li
                                    key="navhtff"
                                    className={style.menu__item}
                                    role="presentation"
                                >
                                    <a
                                        className="text-nav"
                                        href="https://hammarbytff.web.sportadmin.se/start/?ID=315602"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        HTFF
                                    </a>
                                </li>

                                <li
                                    key="navungdom"
                                    className={style.menu__item}
                                    role="presentation"
                                >
                                    <a
                                        className="text-nav"
                                        href="https://www.hammarbyungdom.se/start/?ID=122475"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Ungdom
                                    </a>
                                </li>

                                <li
                                    key="navsamhallsmatchen"
                                    className={style.menu__item}
                                    role="presentation"
                                >
                                    <a
                                        className="text-nav"
                                        href="https://samhallsmatchen.se/"
                                        rel="noreferrer"
                                        target="_blank"
                                    >
                                        Samhällsmatchen
                                    </a>
                                </li>
                            </>
                        }
                    </ul>
                </nav>
                <div className={style.menu__loginMobile}>
                    <AccountLink onClickLink={() => {
                        toggleMenuMobile(false);
                    }} />
                </div>
            </div>
        </>
    );
};

export default Menu;
